import React from "react"
import { SideMenu } from "./SideMenu"
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline"
import MaterialUiTheme from "../theme"
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles"

const styles = require("./styles.module.scss");

// @ts-ignore
import whatsapp from "../images/whatsapp.svg"

const Layout = ({ transparent = false, withFooter = true, children }) => {

  return (
    <>
      <MuiThemeProvider theme={MaterialUiTheme}>
        <CssBaseline/>
        <SideMenu transparent={Boolean(transparent)}>
          {children}
        </SideMenu>
      </MuiThemeProvider>
      <a
        href="https://wa.me/35699578513?text=Hola%2C+Quiero+iniciar+el+proceso+de+planeaci%C3%B3n+de+mi+viaje+de+estudios+a+Malta+con+Desde+Malta%21"
        className={styles.float} target="_blank"
        rel="noopener"
      >
        <img src={whatsapp} alt={"whatsapp-btn"}/>
      </a>
    </>
  )
}

export default Layout
