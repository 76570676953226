import * as React from "react";

const styles = require('./styles.module.scss');

const HideMobile = ({children}) => {
    return (
        <div className={styles.hideMobile}>
            {children}
        </div>
    )

};

export default HideMobile;