import { createMuiTheme } from "@material-ui/core/styles";

const MaterialTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    color: "#808080",
    fontFamily: [
      'Barlow Condensed',
      "sans-serif"
    ]
  },
  palette: {
    primary: {
      main: "#F3C969",
    },
    secondary: {
      main: "#FFFFFF",
    },
  }
});
export default MaterialTheme;