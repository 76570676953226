import React from "react"
import Drawer from "@material-ui/core/Drawer/Drawer"
import ListItemText from "@material-ui/core/ListItemText/ListItemText"
import ListItem from "@material-ui/core/ListItem/ListItem"
import List from "@material-ui/core/List/List"
import AppBar from "@material-ui/core/AppBar/AppBar"
import Toolbar from "@material-ui/core/Toolbar/Toolbar"
import IconButton from "@material-ui/core/IconButton/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import Divider from "@material-ui/core/Divider/Divider"
import HideDesktop from "../Shared/HideDesktop"
import HideMobile from "../Shared/HideMobile"
import Typography from "@material-ui/core/Typography/Typography"
import { Link } from "gatsby"
import css from "classnames"
import LazyLoad from "react-lazyload"

// @ts-ignore
import styles from "./styles.module.scss"
// @ts-ignore
import team from "../../images/greeting.svg"
// @ts-ignore
import house from "../../images/home.svg"
// @ts-ignore
import steps from "../../images/pasos.svg"
// @ts-ignore
import post from "../../images/blog.svg"
// @ts-ignore
import agenda from "../../images/tactics.svg"
// @ts-ignore
import review from "../../images/review.svg"
// @ts-ignore
import book from "../../images/book-auto.svg"
// @ts-ignore
import logo from "../../images/DesdeMaltaLogoNew.svg"
// @ts-ignore
import logoWhite from "../../images/LogoWhite.svg"

interface State {
  isMobileOpen: boolean;
}


interface Props {
  children: any;
  transparent?: boolean;
}

interface MenuItemProps {
  text: string;
  imageSrc: string;
  link?: string;
  onClick?: () => void;
}

const MenuItem = ({ text, imageSrc, link, onClick }: MenuItemProps) => (
  <Link to={link || ""} className={styles.white}>
    <ListItem button key={text} onClick={onClick}><img className={styles.sideMenuIcon} src={imageSrc} alt={text} loading="lazy"/><ListItemText
      primary={text} /></ListItem>
  </Link>
)


const MenuItems = ({toggleMenu}) => (
  <>
    <div className={styles.logoContainer}>
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={toggleMenu}
        className={styles.menuIcon}
      >
        <MenuIcon/>
      </IconButton>
      <img src={logoWhite} style={{ height: "50px" }} alt={"Malta logo"} loading="lazy"/>
    </div>
    <Divider/>
    <List>

      <MenuItem text={"Home"} imageSrc={house} link={"/"}/>
      <MenuItem text={"Paso a paso"} imageSrc={steps} link={"/paso-a-paso/"}/>
      <MenuItem text={"Conócenos"} imageSrc={team} link={"/conocenos/"}/>
      <MenuItem text={"Testimonios"} imageSrc={review} link={"/testimonios/"}/>
      <MenuItem text={"Artículos"} imageSrc={post} link={"/articulos/"}/>
      <MenuItem text={"Auto-estudio"} imageSrc={book} link={"/auto-estudio/"}/>
      <MenuItem text={"Contacto"} imageSrc={agenda} link={"/contacto/"}/>

    </List>
  </>
)

type MergedProps = Props;

class SideMenuComp extends React.Component<Props & MergedProps, State> {

  constructor(props) {
    super(props)
    this.state = {
      isMobileOpen: false
    }
  }

  public render() {
    return (
      <>
        <div className={styles.root}>
          <AppBar position="absolute" className={styles.appBar}>
            <Toolbar>
              <HideDesktop>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.handleDrawerToggle}
                  className={styles.white}
                >
                  <MenuIcon/>
                </IconButton>
              </HideDesktop>
              <HideMobile>
                <div className={styles.toolBar}>
                  <Link to={"/"}>
                    <div className={styles.logoContainer}>
                      <img src={logo} style={{ height: "50px" }} alt={"Logo Desde Malta"}/>
                    </div>
                  </Link>
                  <Link to={"/paso-a-paso/"} className={styles.item}>
                    <Typography variant="h6" className={styles.white} noWrap>
                      Paso a paso
                    </Typography>
                  </Link>
                  <Link to={"/conocenos/"} className={styles.item}>
                    <Typography variant="h6" className={styles.white} noWrap>
                      Conócenos
                    </Typography>
                  </Link>
                  <Link to={"/testimonios/"} className={styles.item}>
                    <Typography variant="h6" className={styles.white} noWrap>
                      Testimonios
                    </Typography>
                  </Link>
                  <Link to={"/articulos/"} className={styles.item}>
                    <Typography variant="h6" className={styles.white} noWrap>
                      Artículos
                    </Typography>
                  </Link>
                  <Link to={"/auto-estudio/"} className={styles.item}>
                    <Typography variant="h6" className={styles.white} noWrap>
                      Auto-estudio
                    </Typography>
                  </Link>
                  <Link to={"/contacto/"} className={styles.item}>
                    <Typography variant="h6" className={styles.white} noWrap>
                      Contacto
                    </Typography>
                  </Link>

                </div>
              </HideMobile>
            </Toolbar>
          </AppBar>
          <nav className={styles.drawer}>
            <HideDesktop>
              <LazyLoad>
              <Drawer
                variant="temporary"
                open={this.state.isMobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: styles.drawer,
                }}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                <MenuItems toggleMenu={this.handleDrawerToggle}/>
              </Drawer></LazyLoad>
            </HideDesktop>
          </nav>
        </div>
        <div
          className={css(styles.content, { [styles.transparent]: this.props.transparent })}>{this.props.children}</div>
      </>
    )
  }

  private handleDrawerToggle = () => {
    this.setState((prevState) => ({
      isMobileOpen: !prevState.isMobileOpen,
    }))
  }
}

export const SideMenu = SideMenuComp